.services {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  background-color: rgba(238, 238, 238, 0.9);
  backdrop-filter: blur(5px);
  scroll-margin-top: 100px;
}

.services .section-text {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
}

.services .section-text h1 {
  font-size: 4em;
  max-width: 900px;
  color: #fff;
  letter-spacing: -5px;
  font-weight: 500;
}

.services .button-container {
  margin-top: 5rem;
}

.services .section-text button {
  background-color: #000;
  color: #fff;
  padding: 1rem;
  font-family: "Barlow";
  font-weight: 500;
  font-size: medium;
  text-transform: uppercase;
  border: none;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.services .section-text button:hover {
  color: #000;
  background-color: transparent;
  border: 2px solid #000;
}

.services .section-text .services-container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: 0 auto;
  width: 90vw;
  gap: 20px;
}

.services .section-text .services-container .service-card {
  max-width: 500px;
  width: 500px;
  height: 550px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #111;
  color: #fff;
}

.services .section-text .services-container .service-card .card-content {
  padding: 1rem;
  height: 200px;
  min-height: 200px;
}

.services .section-text .services-container .service-card h2 {
  font-family: "Bebas Neue";
  font-weight: 100;
  margin-bottom: 0;
  margin-top: 0;
}

.services .section-text .services-container .service-card a {
  color: #fff;
  font-family: "Barlow";
  font-weight: 400;
  border-bottom: 1px solid #fff;
  width: fit-content;
  padding-bottom: 0.2rem;
}

.services .section-text .services-container .service-card img {
  height: 100%;
  max-height: 300px;
  width: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .services {
    height: 100%;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .services .side-title {
    display: none;
  }

  .services .section-text h1 {
    font-size: 3rem;
    transform: scale(0.8);
  }

  .services .section-text .services-container {
    flex-direction: column;
  }

  .services .section-text .services-container .service-card {
    width: auto;
    margin-bottom: 1rem;
  }
}
