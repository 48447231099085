.mobile-header {
  position: fixed;
  max-height: 100px;
  height: 90px;
  display: flex;
  width: 100%;
  margin: 0 auto;
  z-index: 9;
  background-color: #000;
  padding-bottom: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.mobile-header nav {
  position: relative;
  z-index: 9;
  display: flex;
  width: 90vw;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: #fff;
  padding-top: 1rem;
}

.mobile-header nav img {
  max-width: 100px;
}

.mobile-header .menu-icon {
  filter: invert(1);
  transition: all 0.3s ease;
}

.mobile-header .menu-icon.hamburger {
  width: 30px;
}

.mobile-header .menu-icon.close {
  width: 22.5px;
}

.mobile-header nav a {
  margin: 0;
  font-family: "Inter", sans-serif;
}

.mobile-header nav p {
  cursor: pointer;
}

.mobile-header .menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
}

.show {
  opacity: 1 !important;
  pointer-events: all !important;
  backdrop-filter: blur(10px);
}

.mobile-header .menu-overlay ul {
  height: 80vh;
  padding-left: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  gap: 10px;
}

.mobile-header .menu-overlay ul li {
  color: #fff;
  list-style: none;
  text-transform: uppercase;
  font-size: x-large;
  font-family: "Barlow";
}

.mobile-header .menu-overlay .mobile-apply-button {
  border: none;
  color: #fff;
  background-color: transparent;
  font-family: "Barlow";
  text-transform: uppercase;
  font-size: x-large;
  width: fit-content;
  padding: 0;
}
