.hero {
  height: 100vh;
  width: 100%;
  background-size: cover;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.hero .text-container {
  position: relative;
  z-index: 2;
  padding: 2rem;
  width: 80%;
}

.hero .text-container h1 {
  color: #fff;
  font-family: "Bebas Neue", sans-serif;
  font-size: 6em;
  text-transform: uppercase;
  max-width: 500px;
  margin-bottom: 0.5rem;
  line-height: 90px;
}

.hero .text-container h3 {
  font-family: "Barlow";
  font-size: 1.5em;
  font-weight: 500;
  max-width: 600px;
  min-width: 400px;
  color: #fff;
  opacity: 0.8;
}

.hero .text-container h4 {
  font-family: "Barlow";
  font-weight: 500;
  color: #fff;
  border-bottom: 2px solid #fff;
  width: fit-content;
  padding-bottom: 0.5rem;
}

.hero .arrow-down-container {
  display: flex;
  justify-content: center;
}

.hero .arrow-down-container img {
  max-width: 100px;
  min-width: 50px;
  filter: grayscale(100);
}

.hero .logo {
  width: 600px;
}

.hero .overlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 768px) {
  .hero .logo {
    width: 300px;
    margin-top: 5rem;
  }

  .hero .arrow-down-container img {
    max-width: 50px;
  }

  .hero .text-container h1 {
    font-size: 3em;
    line-height: normal;
  }

  .hero .text-container h3 {
    font-size: 1em;
    max-width: 300px;
    min-width: 200px;
  }
}
