.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  border-radius: 8px;
  width: 100%;
  margin: 0 auto;
  backdrop-filter: blur(15px);
  scroll-margin-top: 100px;
}

.testimonials h1 {
  font-size: 4em;
  max-width: 750px;
  color: #fff;
  font-weight: 500;
  margin: 0;
  font-family: "Barlow";
  text-align: center;
}

.testimonial {
  text-align: center;
  margin-top: 1rem;
  max-width: 700px;
}

.testimonial p {
  font-size: 1.2rem;
  margin: 0.5rem 0;
  color: #fff;
}

.author {
  font-style: italic;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .testimonials h1 {
    font-size: 2em;
  }
}
