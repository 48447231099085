.contact {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(238, 238, 238, 0.9);
  backdrop-filter: blur(5px);
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 1);
  scroll-margin-top: 100px;
}

.contact .container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.contact .column-one {
  display: flex;
  align-items: center;
  height: 100vh;
}

.contact .column-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
}

.contact .section-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.contact .section-text h1 {
  font-size: 3em;
  max-width: 100%;
  margin: 0;
  font-weight: 500;
  margin-bottom: 1rem;
  font-family: "Barlow";
}

.contact .section-text h2 {
  font-family: "Barlow";
  font-weight: 400;
  text-align: center;
  margin-top: 0;
  margin-bottom: 4rem;
  max-width: 800px;
}

.contact .contact-button-container button {
  background-color: #fff;
  padding: 1.5rem 6rem;
  border: none;
  border-radius: 10px;
}

.contact .contact-button-container button a {
  font-family: "Poppins";
  text-transform: uppercase;
  color: #000;
}

.contact .contact-form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact .contact-form-container form {
  width: 100%;
}

.contact .contact-form-container form div {
  display: flex;
  flex-direction: column;
  color: #000;
}

.contact .contact-form-container form div label {
  font-family: "Barlow";
  font-size: 1.5em;
}

.contact .contact-form-container form div input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #000;
  margin-right: 1rem;
  color: var(--primary-text-color);
  font-family: "Barlow";
  font-size: 2em;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.contact .contact-form-container form div input:focus {
  outline: none;
}

.contact .contact-form-container form .top-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.contact .contact-form-container form .top-row div {
  width: 50%;
}

.contact .contact-form-container form .message-container textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #000;
  color: #000;
  font-family: "Barlow";
  font-size: 1.5em;
}

.contact .contact-form-container form .message-container textarea::placeholder {
  font-family: "Barlow";
  color: #000;
  font-size: 1em;
}

.contact .contact-form-container form select {
  border: none;
  border-bottom: 1px solid #000;
  background-color: transparent;
  font-family: "Barlow";
  color: #000;
  font-size: 1.25em;
  padding-left: 0;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

.contact .contact-form-container form select option {
  color: #000;
}

.contact-form-container form button {
  width: 100%;
  max-width: 200px;
  margin-top: 2rem;
  background-color: #000;
  border: 1px solid transparent;
  border-radius: 5px;
  font-family: "Barlow";
  font-size: 2em;
  color: #fff;
  padding: 1rem 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.contact-form-container form button:hover {
  color: #000;
  background-color: transparent;
  border: 1px solid #000;
}

.contact .contact-form-container .message-status {
  color: var(--primary-text-color);
  font-family: var(--body-font);
  font-size: 1.25em;
  max-width: 600px;
  text-align: center;
  opacity: 0;
  font-weight: lighter;
  transition: all 0.5s ease;
  margin-top: -0.7rem;
}

.contact .contact-form-container .message-status.show {
  opacity: 0.6;
  margin-top: 0;
}

.contact .contact-form-container .message-status p {
  color: #fff;
  font-family: "Poppins";
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .contact {
    width: 100%;
  }

  .contact .container {
    width: 300px;
    padding: 0;
    margin: 0 auto;
  }

  .contact .contact-form-container form .top-row {
    flex-direction: column;
  }

  .contact .contact-form-container form .top-row div {
    width: 100%;
    margin-bottom: 1rem;
  }

  .contact .contact-form-container form button {
    margin-bottom: 2rem;
  }

  .contact .container .section-text {
    margin-top: 1rem;
  }

  .contact .container .section-text h1 {
    font-size: 2em;
    text-align: center;
  }

  .contact .container .section-text h2 {
    font-size: 1.25em;
  }

  .contact .contact-form-container form div input {
    font-size: 1em;
  }

  .contact .contact-form-container form .message-container textarea {
    font-size: 1em;
  }

  .contact .container .contact-button-container {
    margin-bottom: 2.5rem;
    margin-top: 1rem;
  }

  .contact-form-container form button {
    max-width: 100%;
  }
}
