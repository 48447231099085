.post-hero {
  width: 100%;
  background-color: #fff;
  padding-top: 5rem;
  padding-bottom: 5rem;
  scroll-margin-top: 100px;
}

.post-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.post-hero h1 {
  font-family: "Barlow";
  font-size: 3em;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}

.post-hero h2 {
  font-size: 1.6em;
  max-width: 1000px;
  width: fit-content;
  font-weight: 400;
  font-family: "Barlow";
  text-align: center;
  margin-top: 2rem;
}

.post-hero .button-container {
  display: flex;
  width: 80%;
  justify-content: space-around;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.post-hero .button-container button {
  width: 45%;
  background-color: #000;
  color: #fff;
  padding: 1rem;
  font-family: "Barlow";
  font-weight: 500;
  font-size: medium;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.post-hero .button-container button:hover {
  color: #000;
  background-color: #fff;
  border: 2px solid #000;
}

@media only screen and (max-width: 768px) {
  .post-hero h1 {
    font-size: 1.5em;
    padding: 1.5rem;
    font-weight: 700;
  }

  .post-hero h2 {
    font-size: 1.2em;
    font-weight: 500;
    max-width: 300px;
    margin-top: 0;
  }

  .post-hero {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .post-hero .button-container button {
    font-size: small;
  }
}
