.faq {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  background-color: #fff;
  scroll-margin-top: 100px;
}

.faq .column-one {
  display: flex;
  align-items: center;
}

.faq .column-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.faq .section-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.faq .section-text h1 {
  font-size: 3em;
  color: #000;
  font-weight: 500;
  font-family: "Barlow";
  font-weight: 500;
}

.accordion {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.accordion-item {
  margin-bottom: 10px;
  overflow: hidden;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding-bottom: 0.5rem;
  color: #000;
  cursor: pointer;
  flex-direction: column;
}

.accordion-header .question {
  font-size: x-large;
  font-family: "Barlow";
  text-align: center;
  font-weight: 500;
}

.accordion-content {
  background-color: transparent;
  color: #000;
  max-height: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

.accordion-content.active {
  max-height: 200px;
  opacity: 1;
}

.accordion-content .answer {
  display: block;
  width: 100%;
  text-align: center;
  font-family: "Barlow";
  font-weight: 400;
  font-size: 1.25em;
  margin-bottom: 2rem;
}

.accordion-icon {
  font-size: 3em;
  font-weight: bold;
}

.accordion-icon.minus {
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
  font-family: "Barlow";
  font-weight: 400;
}

.accordion-icon.plus {
  transform: rotate(0);
  transition: transform 0.3s ease-in-out;
  font-family: "Barlow";
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .faq .column-one {
    display: none;
  }

  .faq .column-two {
    width: 90%;
    margin: 0 auto;
  }

  .faq .column-two .section-text h1 {
    font-size: 2em;
    text-align: center;
  }
}
