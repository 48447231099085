.markets-sectors {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.markets-sectors .column-one {
  display: flex;
  align-items: center;
  height: 100vh;
}

.markets-sectors .column-two {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
}

.markets-sectors .side-title {
  color: #fff;
  transform: rotate(-90deg);
  font-size: 4em;
}

.markets-sectors .section-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.markets-sectors .section-text h2 {
  font-size: 3em;
  max-width: 100%;
  font-weight: 500;
  font-family: "Barlow";
  margin-bottom: 0.6rem;
  margin-top: 0;
}

.markets-sectors .section-text h3 {
  font-family: "Barlow";
  font-weight: 400;
  margin-top: 0;
}

.markets-sectors .table {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 50%;
}

.markets-sectors .table .tabs {
  display: flex;
}

.markets-sectors .table .tabs h3 {
  position: relative;
  z-index: 0;
  background-color: #111;
  color: #fff;
  padding: 1rem 2rem;
  margin-bottom: 0;
  cursor: pointer;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 2em;
}

.markets-sectors .table .tabs h3:first-child {
  position: relative;
}

.markets-sectors .table .tabs h3:nth-child(2) {
  margin-left: -1rem;
}

.markets-sectors .table .tabs .tab.active {
  background-color: #fff;
  color: #111;
  transition: background-color 0.2s ease-in;
  z-index: 1;
}

.markets-sectors .table .content {
  display: flex;
  padding: 1rem;
  min-width: 500px;
  border-radius: 10px;
  border-top-left-radius: 0;
  justify-content: space-evenly;
}

.markets-sectors .table .content .list {
  color: #000;
}

.markets-sectors .table .content .list ul li {
  font-size: 1.5em;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .markets-sectors {
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
  }

  .markets-sectors .section-text {
    width: 90%;
  }

  .markets-sectors .section-text h2 {
    font-size: 2em;
    font-weight: 600;
    text-align: center;
  }

  .markets-sectors .section-text h3 {
    text-align: center;
    max-width: 200px;
    font-size: 1em;
  }

  .mobile-table {
    width: 90%;
    margin: 0 auto;
  }

  .mobile-table ul {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .mobile-table h4 {
    font-size: 2em;
    transform: scale(0.8);
    background-color: #fff;
    color: #000;
    padding: 0.5rem;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    font-family: "Barlow";
    margin-bottom: 0;
  }

  .mobile-table li {
    color: #000;
    font-size: 1.3em;
  }
}
