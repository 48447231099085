.services-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.services-page .banner {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.services-page .description-container {
  background-color: #fff;
}

.services-page .description-container {
  padding: 5rem;
  font-family: "Barlow";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-page .description-container h1 {
  font-size: 3em;
  font-weight: 500;
  margin-bottom: 0;
}

.services-page .description-container h3 {
  font-weight: 500;
  max-width: 800px;
  text-align: center;
}

.services-page .title {
  margin-bottom: 5rem;
  margin-left: 5rem;
  max-width: 700px;
  color: #fff;
}

.services-page .title h1 {
  margin-top: 10rem;
  font-weight: 500;
  font-family: "Bebas Neue";
  font-size: 3em;
  margin-bottom: 0;
}

.services-page .title h3 {
  font-family: "Barlow";
  font-weight: 400;
  margin-top: 0;
}

.services-page .title a {
  font-family: "Barlow";
  font-weight: 600;
  border-bottom: 1px solid #fff;
  padding-bottom: 0.5rem;
}

.services-page .title .line {
  background-color: #fff;
  height: 350px;
  width: 5px;
}

.services-page .services-list-container {
  width: 100%;
  background-color: #fff;
}

.services-page .services-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
}

.services-page .services-list h1 {
  text-align: center;
  margin-bottom: 10rem;
  font-weight: 500;
}

.services-page .services-list ul > li::marker {
  font-weight: bold;
}

.services-page .services-list ul > li > h2 {
  text-align: left;
  font-family: "Bebas Neue";
  font-weight: 500;
}

.services-page .services-list ul {
  display: flex;
  list-style: decimal-leading-zero;
  flex-wrap: wrap;
}

.services-page .services-list ul > li {
  color: #000;
  margin-bottom: 1rem;
  font-size: 2em;
  width: 50%;
  box-sizing: border-box;
  padding-right: 6rem;
  scroll-margin-top: 300px;
}

.services-page .services-list ul > li > h2 {
  font-size: 1.25em;
  margin-bottom: 0.5rem;
  max-width: none;
}

.services-page .services-list ul > li > p {
  margin-top: 0.5rem;
  font-family: "Barlow";
  font-size: 0.6em;
}

.services-page .services-list .image-container {
  width: 400px;
  height: 400px;
  overflow: hidden;
}

.services-page .services-list .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 768px) {
  .services-page .banner {
    padding-top: 6.5rem;
    padding-bottom: 4rem;
  }

  .services-page .banner .title {
    margin-left: 0;
  }

  .services-page .title {
    margin-bottom: 0;
  }

  .services-page .title h1 {
    font-size: 2em;
    margin-top: 3rem;
  }

  .services-page .title h3 {
    font-size: 1em;
    max-width: 300px;
  }

  .services-page .description-container {
    padding: 3rem;
  }

  .services-page .description-container h1 {
    text-align: center;
    margin-top: 0;
    font-size: 2em;
    font-weight: 600;
  }

  .services-page .services-list ul {
    list-style: decimal-leading-zero;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .services-page .services-list ul > li {
    color: #000;
    margin-bottom: 1rem;
    margin-left: 1rem;
    padding-right: 0;
    width: 90%;
    font-size: 1.5em;
  }

  .services-page .services-list ul > li > h2 {
    margin-bottom: 0.5rem;
    max-width: none;
  }

  .services-page .services-list ul > li > p {
    margin-top: 0.5rem;
    font-family: "Barlow";
  }

  .services-page .title .line {
    height: 200px;
  }

  .services-page .services-list .image-container {
    width: 250px;
    height: 250px;
  }

  .services-page .services-list h1 {
    font-size: 2em;
    width: fit-content;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
