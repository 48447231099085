.gallery-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gallery-page .banner {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.gallery-page .title {
  margin-bottom: 5rem;
  margin-left: 5rem;
  max-width: 700px;
  color: #fff;
}

.gallery-page .title h1 {
  margin-top: 10rem;
  font-weight: 500;
  font-family: "Bebas Neue";
  font-size: 3em;
  margin-bottom: 0;
}

.gallery-page .title h3 {
  font-family: "Barlow";
  font-weight: 400;
  margin-top: 0;
}

.gallery-grid-container {
  width: 100vw;
  background-color: #fff;
  height: 100%;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(350px, 1fr)
  ); /* Responsive columns */
  gap: 1rem; /* Gap between items */
  padding: 5rem;
}

.gallery-item {
  overflow: hidden;
  border-radius: 8px; /* Optional: Rounds the corners of the images */
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 2rem;
  }

  .gallery-page .banner {
    padding-left: 5rem;
    padding-top: 6.5rem;
    padding-bottom: 4rem;
  }

  .gallery-page .banner .title {
    margin-left: 0;
    margin-bottom: 0;
  }

  .gallery-page .banner .title h1 {
    font-size: 2em;
    margin-top: 3rem;
  }

  .gallery-page .banner .title h3 {
    font-size: 1em;
    max-width: 300px;
  }
}
