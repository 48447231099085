.footer {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(15px);
}

.footer .content {
  display: flex;
  width: 90%;
  align-items: center;
  padding: 2rem;
  justify-content: space-between;
}

.footer .content .logo-container {
  margin-right: 5rem;
}

.footer .content .logo-container img {
  width: 100%;
  max-width: 300px;
}

.footer .content .content-container {
  display: flex;
  flex-direction: row;
  text-align: left;
  font-family: "Barlow";
  color: #fff;
  width: 100%;
}

.footer .content .content-container .contact-container h3 {
  font-size: 2em;
  font-weight: 500;
  margin-top: 1rem;
}

.footer .content .content-container .sitemap-container h3 {
  font-size: 2em;
  font-weight: 500;
  margin-top: 1rem;
}

.footer .content .content-container ul {
  list-style: none;
  padding-left: 0;
  margin-right: 5rem;
}

.footer .content .content-container ul > li {
  margin-bottom: 1rem;
}

.footer .content .content-container ul > li > h4 {
  margin-bottom: 0;
}

.footer .content .content-container ul > li > a {
  opacity: 0.9;
  border-bottom: 1px solid #fff;
  padding-bottom: 0.3rem;
}

.footer
  .content
  .content-container
  ul
  > .footer
  .content
  .content-container
  h1 {
  font-size: 3em;
  max-width: 100%;
  min-width: 650px;
  font-weight: 500;
}

.footer .content .content-container h1,
.footer .content .content-container h3 {
  margin-bottom: 0.5rem;
}

.footer .copyright-section {
  display: flex;
  color: #fff;
  margin-left: 5rem;
}

.footer .copyright-section h3 {
  font-size: 1em;
  text-transform: capitalize;
  font-weight: normal;
  margin-top: 0;
  font-family: "Barlow";
}

@media only screen and (max-width: 768px) {
  .footer .content {
    flex-direction: column;
    padding: 0;
    width: 100%;
  }

  .footer .content .logo-container {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    margin-top: 2rem;
    width: 100%;
  }

  .footer .content .logo-container img {
    width: 150px;
  }

  .footer .content .content-container {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 4rem;
  }

  .footer .content .content-container h1 {
    text-align: center;
    font-size: 1.4em;
  }

  .footer .content .content-container .contact-container h3 {
    font-size: 1.5em;
  }

  .footer .content .content-container .sitemap-container h3 {
    font-size: 1.5em;
  }

  .footer .content .content-container ul {
    margin-right: 1rem;
  }

  .footer .copyright-section {
    width: auto;
    align-items: flex-start;
    margin-left: 2rem;
    margin-top: 2rem;
  }

  .footer .copyright-section h3 {
    text-align: left;
    font-size: 1em;
  }
}
