@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

p,
li,
span {
  font-family: "Barlow";
}

a {
  text-decoration: none;
  color: unset;
}

.main {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow-x: hidden;
  height: 100%;
}

.main::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

section {
  position: relative;
  z-index: 1;
}
/* 
section:not(:first-of-type) {
  margin-top: 10rem;
} */

.button {
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  border: none;
  font-family: "Poppins";
  text-transform: uppercase;
  font-size: 1em;
  padding: 2rem;
}

@media only screen and (max-width: 768px) {
  .main {
    background-size: cover;
    background-size: 1000px;
    background-position-y: 100px;
    background-position-x: -150px;
    background-repeat: repeat;
  }

  /* section:not(:first-of-type) {
    margin-top: 10rem;
  } */
}
