.quote-block {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: #fff;
  max-width: 300px;
  border-top-right-radius: 15px;
  transition: opacity 0.3s ease-in-out, margin-left 0.3s ease-in-out;
  z-index: 9;
  box-shadow: 0px 3px 3px 6px rgba(0, 0, 0, 0.2);
}

.inactive {
  opacity: 0;
  margin-left: -1rem;
  pointer-events: none;
}

.quote-block p {
  font-size: small;
}

.quote-block .button-container {
  display: flex;
  gap: 5px;
}

.quote-block .button-container button {
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 0.25rem;
}

.quote-block .button-container button a {
  color: #000;
  text-decoration: none;
}

.close-container {
  display: flex;
  justify-content: flex-end;
}

.close-container img {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .quote-block {
    max-width: 100vw;
    width: 100vw;
    border-top-right-radius: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 0;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .quote-block p {
    text-align: center;
    max-width: 300px;
  }

  .quote-block .button-container button {
    padding: 0.5rem;
  }

  .close-container img {
    width: 15px;
  }
}
