.header {
  position: fixed;
  max-height: 100px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 0 auto;
  z-index: 9;
  background-color: #111;
  padding: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.header nav {
  display: flex;
  width: 800px;
}

.header nav ul {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.header nav ul li {
  text-transform: capitalize;
  font-size: medium;
  font-weight: 400;
}

.header nav ul li a {
  text-decoration: none;
  color: #fff;
}

.header img {
  max-width: 100px;
}

.apply-button {
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  border: none;
  padding: 1rem 2rem;
  border-bottom-left-radius: 15px;
  display: none;
}

.apply-button a {
  font-family: "Poppins";
  text-transform: uppercase;
  font-size: medium;
  color: #000;
  text-decoration: none;
}
